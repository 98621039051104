import * as React from 'react'
import Illustration from 'svgs/newDesigns/fourzerofrour.svg'
import Layout from 'layouts/main'

// styles
const pageStyles = {
  color: '#232129',
  minHeight: '100vh',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}
const headingStyles = {
  marginTop: 100,
  marginBottom: 0,
  fontSize: '40px',
  textTransform: 'uppercase',
  letterSpacing: '0.3px',
}

const paragraphStyles = {
  marginBottom: 48,
}

const imgStyles = {
  maxWidth: '100%',
}

// markup
const NotFoundPage = () => {
  return (
    <Layout showFooter={false}>
    <main style={pageStyles}>
      <title>Not Found</title>
      <Illustration style={imgStyles} />
      <h1 style={headingStyles}>LookS like you’re lost </h1>
      <p style={paragraphStyles}>
        The page you are looking for is not avaible!
      </p>
    </main>
    </Layout>
  )
}

export default NotFoundPage
